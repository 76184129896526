import React, { Fragment, useState } from "react";
import Photo from "../../components/Photo";
import "../../styles/pricing_mbl.scss";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { useTranslation, Trans, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import PricingDetials from './price';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Price = ({ children }) => {
  const context = React.useContext(I18nextContext);
  const { t } = useTranslation()

  const [showMe, setShowMe] = useState(false)

  const [value, setValue] = useState("1")
  const [Deliveryshow, setDeliveryshow] = useState(false)
  const [Employeeshow, setEmployeeshow] = useState(false)
  const [RoleandshowMe, setRoleandshowMe] = useState(false)
  const [showMeaccount, setshowMeaccount] = useState(false)
  const [report, setreport] = useState(false)
  const [customdate, setcustomdate] = useState(false)

  const [Warehouse, setWarehouse] = useState(false)
  const [PickUp, setPickUp] = useState(false)

  const [Inventory, setInventory] = useState(false)
  const [PortalBasic, setPortalBasic] = useState(false)

  const [CRMshow, setCRMshow] = useState(false)
  const [showDashboard, setshowDashboard] = useState(false)
  const [Portal, setPortal] = useState(false)
  const [Reportversions, setReportversions] = useState(false)
  const [shipment, setShipment] = useState(false)

  const [isShowArrow, setIsShowArrow] = useState(false)
  const [isjobShowArrow, setIsjobShowArrow] = useState(false)


  const [Userversion, setuserversion] = useState(false)

  const [Specialfeatures, setSpecial] = useState(false)

  const [isToggle, setToggleValue] = useState(false);
  const [elementId, setElementId] = useState("");

  const heightEqualizer = () => {
    setTimeout(() => {
      let GreaterHeight = null;
      let silvedElem = document.querySelector("#silverpulseid > ul");
      let goldElem = document.querySelector("#goldpulseid > ul");
      let diamElem = document.querySelector("#diamondpulseid > ul");
      if (silvedElem != null && goldElem != null && diamElem != null) {
        let silvedElemHeight = silvedElem.clientHeight;
        let goldElemHeight = goldElem.clientHeight;
        let diamElemHeight = diamElem.clientHeight;
        if (silvedElemHeight >= goldElemHeight) {
          if (silvedElemHeight >= diamElemHeight) {
            GreaterHeight = silvedElemHeight;
          } else {
            GreaterHeight = diamElemHeight;
          }
        } else {
          if (goldElemHeight >= diamElemHeight) {
            GreaterHeight = goldElemHeight;
          } else {
            GreaterHeight = diamElemHeight;
          }
        }
        silvedElem.style.minHeight = GreaterHeight + "px";
        goldElem.style.minHeight = GreaterHeight + "px";
        diamElem.style.minHeight = GreaterHeight + "px";
      }

    }, 100)
  }
  const hideEqualizer = () => {
    setShipment(!shipment);
    setShowMe(!showMe);
    setDeliveryshow(!Deliveryshow);
    setEmployeeshow(!Employeeshow);
    setRoleandshowMe(!RoleandshowMe);
    setshowMeaccount(!showMeaccount);
    setreport(!report);
    setcustomdate(!customdate);
    setWarehouse(!Warehouse);
    setPickUp(!PickUp);
    setInventory(!Inventory);
    setPortalBasic(!PortalBasic);
    setCRMshow(!CRMshow);
    setshowDashboard(!showDashboard);
    setPortal(!Portal);
    setReportversions(!Reportversions);
    setuserversion(!Userversion);
    setSpecial(!Specialfeatures);


  }

  const operation0 = (event, contentId) => {
    let id = event.target.id;

    if (elementId != contentId) {
      setElementId(contentId);
      setToggleValue(true);
    } else {
      setElementId(contentId);
      setToggleValue(!isToggle);
    }

    if (id === "showRightArrow") {
      setIsShowArrow(true);
    }
    else if (id === "showDownArrow") {
      setIsShowArrow(false);
    }
    else if (id === "divId") {
      setIsShowArrow(!isShowArrow);
    }

    heightEqualizer();
  }



  const getTranslated = text => {
    return t(text);
  }

  const handleChange = event => {
    setValue(event.target.value)

  }

  const [isTogglePulse, setisTogglePulse] = useState(false)

  const [id, setid] = useState(" ")


  const minimizeone = (id) => {

    setid(id)

    setisTogglePulse(!isTogglePulse)

    // value: '1'
  }

  return (
    <div className="layout">
      <main>{children}</main>
      <PricingDetials />
      <div className="banner-feature pricing-features-header mbl_pricing_view" lang={context.language}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="overlay-freight"></div>

              <div className="right-img"></div>
            </div>

            <div className="col-md-12">
              <div className="content-imgs pricing-imgs">
                <div className="pricing_details_cont" lang={context.language}>
                  <Link to="/"><Trans>HOME</Trans></Link> / <span className="features_mbl"><Link to="/pricing/"><Trans>PRICING</Trans></Link></span>
                </div>
                <div className="sub_main" lang={context.language}>
                  <Trans>Pick and pay only</Trans><br></br><Trans>for what you need!</Trans>
                </div>
                <div className="subsub_main" lang={context.language}>
                  <Trans>Find the most suitable module combo for your company.</Trans>

                  <br></br>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pricing-content mbl_pricing_view" lang={context.language}>
        <div className="container">
          <div className="row divlook_align">
            <div className="col-md-12"></div>

            <div className="col-md-6">
              <div className="semi_content" lang={context.language}>
                <Trans>Pay only for</Trans><br></br><Trans>the service you use!</Trans>{" "}
              </div>
              <div className="first_content" lang={context.language}>
                <Trans>
                  The advantages of SaaS and Cloud software solutions are cost-efficient and advanced. If you are looking to establish the least resource and support, within a budget, SaaS is the answer.
                </Trans>
                <br></br>
                <div className="second_content" lang={context.language}>
                  <Trans>
                    You won’t have to worry about the integration, upgrade, scalability, and expert support.
                  </Trans>

                </div>
              </div>

            </div>

            <div className="col-md-6 ">

              <div className="semi_content" lang={context.language}>
                <Trans>Pay only for</Trans><br></br><Trans>the time you use!</Trans>
              </div>


              <div className="first_content" lang={context.language}>
                <Trans>If you are experimenting or just a beginner as well as if you have a established firm, SaaS is the best option as you need not have to pay for the whole software.</Trans>

                <br></br>
                <div className="second_content" lang={context.language}>
                  <Trans>You can pick the Logipulse modules you require for your services and also you can choose the duration of usage,</Trans>
                  <Trans>and hence pay only for that! Now explore to know more!</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="pricing-details mbl_pricing_view" lang={context.language}>
        <div className="container">
          <div className="row  column_alignment" lang={context.language}>
            <div className="col-md-12"></div>

            <div className="col-md-5">
              <div className="tab-navigations" lang={context.language}>
                <select id="select-box" value={value} onChange={handleChange} className="restthis" lang={context.language}>
                  <option style={{ padding: "20px" }} value="0" label={getTranslated("Select a product")}>
                    {getTranslated("Select a product")}
                  </option>
                  <option style={{ padding: "20px" }} value="1" label={getTranslated("FREIGHT FORWARDING")}>
                    {getTranslated("FREIGHT FORWARDING")}
                  </option>
                  {/* <option value="2" label={getTranslated("3PL & WAREHOSING")}>
                    {getTranslated("3PL & WAREHOSING")}
                  </option> */}
                  {/* <option value="3" label={getTranslated("N. V. O. C. C")}>
                    {getTranslated("N. V. O. C. C")}
                  </option> */}
                  {/* <option
                    value="4"
                    label={getTranslated("MOVERS & RELOCATION")}
                  >
                    {getTranslated("MOVERS & RELOCATION")}
                  </option> */}
                  {/* <option value="5" label={getTranslated("BROCKERING")}>
                    {getTranslated("BROCKERING")}
                  </option> */}
                  {/* <option value="6" label={getTranslated("OTHER PRODUCTS")}>
                    {getTranslated("OTHER PRODUCTS")}
                  </option> */}
                  {/* <option
                    value="7"
                    label={getTranslated("PDA DEVICE AND APPLICATION")}
                  >
                    {getTranslated("PDA DEVICE AND APPLICATION")}
                  </option> */}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="detailed-tab mbl_pricing_view" lang={context.language}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 position-tops">
              {value === "0" || value === "1" ? (
                <div id="tab-1" className="tab-contents freight-frwdn">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="box box1">
                        <div className="head" onClick={() => minimizeone("id0")} id="id0">
                          <h3>
                            <Trans>Silver Pulse</Trans>
                          </h3>
                          <Photo
                            src="white-logo.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        {id === "id0" && isTogglePulse ? (<Fragment>


                          <div className="content" id="silverpulseid">



                            <div className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'shipment')}>
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Shipment</Trans>

                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'shipment')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'shipment')} /></span>
                                )
                              }
                              <hr></hr>

                            </div>


                            {elementId === 'shipment' && isToggle === true ? (

                              <Fragment>
                                <ul id="shipmentul">
                                  <li className="position_relative category_points commonhover" >

                                    <Trans>Consolidation</Trans>
                                    <div className="position_absolute content_explanation">
                                      <div className="arrow-up"></div>

                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </div>
                                  </li>
                                  <li>
                                    <Trans>Copy Shipment</Trans>
                                  </li>
                                  <li>
                                    <Trans>Damage, Missing, Recovery marking,</Trans>
                                  </li>
                                  <li>
                                    <Trans>Document Printing</Trans>
                                  </li>
                                  <li>
                                    <Trans>Excel Export</Trans>
                                  </li>
                                  <li>
                                    <Trans>Export, Import, Direct, House</Trans>
                                  </li>
                                  <li><Trans>Income and Expense Report</Trans></li>
                                  <li><Trans>Individual Shipment Item Tracking</Trans></li>
                                  <li><Trans>Invoice/Bill</Trans></li>
                                  <li><Trans>Ocean, Air, Ground Shipments</Trans></li>
                                  <li><Trans>One Click Manifest</Trans></li>
                                  <li><Trans>Receipt/Voucher/Expense Voucher</Trans></li>
                                  <li><Trans>Shipment State Management</Trans></li>

                                </ul>
                                <hr></hr>
                              </Fragment>

                            ) : null}






                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'job')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Job</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'job')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'job')} /></span>
                                )
                              }
                            </div>

                            {elementId === 'job' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li>
                                  <Trans>Air Waybill</Trans>
                                </li>
                                <li>
                                  <Trans>Consignment Note</Trans>
                                </li>
                                <li>
                                  <Trans>Consolidation</Trans>
                                </li>
                                <li>
                                  <Trans>Delivery Order</Trans>
                                </li>
                                <li>
                                  <Trans>Document Printing</Trans>
                                </li>
                                <li>
                                  <Trans>
                                    Excel Export
                                  </Trans>
                                </li>
                                <li>
                                  <Trans>Generate Invoice/Bill</Trans>
                                </li>
                                <li><Trans>Manifest</Trans></li>
                                <li><Trans>Ocean Bill of Lading</Trans></li>
                                <li><Trans>Payments</Trans></li>
                                <li><Trans>Receipt/voucher</Trans></li>
                                <li><Trans>Truck Waybill</Trans></li>

                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'delivery')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Delivery</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'delivery')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'delivery')} /></span>
                                )
                              }
                            </div>

                            {elementId === 'delivery' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li><Trans>Dispatch Delivery</Trans></li>
                                <li><Trans>Excel Export</Trans></li>
                                <li><Trans>Item Damage marking</Trans></li>
                                <li><Trans>Proof of Delivery</Trans></li>
                                <li><Trans>Reopen and Modification</Trans></li>
                                <li><Trans>Employee Hierarchy</Trans></li>
                                <li><Trans>Role based Employees</Trans></li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'warehouse')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Warehouse</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'warehouse')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'warehouse')} /></span>
                                )
                              }
                            </div>

                            {elementId === 'warehouse' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li>
                                  <Trans>Invoice/Bill</Trans>
                                </li>
                                <li>
                                  <Trans>Payments</Trans>
                                </li>
                                <li><Trans>Receipt</Trans>/<Trans>Voucher</Trans></li>

                                <div className="bals"></div>

                                <br></br>
                              </ul>
                            ) : null}
                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'employee')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Employee</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'employee')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'employee')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'employee' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li>
                                  <Trans>Employee Hierarchy</Trans>
                                </li>
                                <li>
                                  <Trans>Role based Employees</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'pickup')}
                            >
                              <CancelOutlinedIcon className="icotesting" />
                              <Trans>Pick Up</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'pickup')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'pickup')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'pickup' && isToggle === true ? (
                              <ul>
                                <div>
                                  {" "}
                                  <hr></hr>
                                </div>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>

                            <div
                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'accounting')}
                            >
                              <CancelOutlinedIcon className="icotesting" />
                              <Trans>Accounting</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'accounting')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'accounting')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'accounting' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                {/* <li>
                                <Trans>
                                  Financial Period
                                </Trans>
                              </li> */}

                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'role')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Role and Permission</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'role')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'role')} /></span>
                                )
                              }
                            </div>

                            {elementId === 'role' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li>
                                  <Trans>Agency Branch wise Permission</Trans>
                                </li>
                                <li>
                                  <Trans>Customizable  Permission Module</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'custom')}
                            >
                              <CancelOutlinedIcon className="icotesting" />
                              {/* <CheckCircleOutlineOutlinedIcon className="materialstesting" /> */}
                              <Trans>Customer</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'custom')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'custom')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'custom' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                {/* <li>
                                <Trans>Custom date selection</Trans>
                              </li> */}
                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'report')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Quotation</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'report')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'report')} /></span>
                                )
                              }
                            </div>

                            {elementId === 'report' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li>
                                  <Trans>Air, Ocean, Ground</Trans>
                                </li>
                                <li><Trans>Approval Flow</Trans></li>
                                <li><Trans>Conversion Report</Trans></li>
                                <li><Trans>Generate Shipment</Trans>
                                  /<Trans>Pickup</Trans></li>
                                <li><Trans>Print and Export PDF</Trans></li>
                                <li><Trans>Send Email</Trans></li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'inventory')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />

                              <Trans>Multi Branch</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'inventory')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'inventory')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'inventory' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                  <li><Trans>Communication between branch employees</Trans></li>
                                </div>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'portal')}
                            >
                              <CancelOutlinedIcon className="icotesting" />
                              <Trans>Portal</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'portal')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'portal')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'portal' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                  {/* <li></li> */}
                                </div>
                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'crm')}
                            >

                              <CancelOutlinedIcon className="icotesting" />
                              <Trans>CRM</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'crm')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'crm')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'crm' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <div className="bals"></div>

                              </ul>
                            ) : null}

                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'dashboard')}
                            >
                              <CancelOutlinedIcon className="icotesting" />

                              <Trans>Dashboard</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'dashboard')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'dashboard')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'dashboard' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                </div>

                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>


                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'vendor')}
                            >
                              <CancelOutlinedIcon className="icotesting" />
                              {/* <CheckCircleOutlineOutlinedIcon className="materialstesting" /> */}
                              <Trans>Vendor</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'vendor')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'vendor')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'vendor' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                </div>
                                {/* <li>
                                <Trans>Vendor</Trans>
                              </li> */}
                                <div className="bals"></div>
                              </ul>
                            ) : null}



                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'user')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>User</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'user')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'user')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'user' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                </div>
                                <li>
                                  <Trans>User management</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}





                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'special')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Special Features</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'special')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'special')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'special' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                </div>
                                <li>
                                  <Trans>Support multi units</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                          </div>

                          <div className="foot">
                            <div className="price">
                              <h5>
                                <Trans>To optimize foundation operations,</Trans>
                                <br></br>
                                <Trans>
                                  start with Silver.
                                </Trans>
                              </h5>
                              {/* <a href="">Terms & Condition applied</a> */}
                            </div>
                            <div className="purchase-btn" lang={context.language}>
                              <Link to="/purchase/" lang={context.language}>
                                <Trans>BUY NOW</Trans>
                              </Link>
                            </div>
                          </div>
                        </Fragment>) : null}
                      </div>

                    </div>



                    <div className="col-md-4">
                      <div className="box box2">
                        <div className="head" onClick={() => minimizeone("id1")} id="id1">
                          <h3>
                            <Trans>Gold Pulse</Trans>
                          </h3>
                          <Photo
                            src="white-logo.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        {id === "id1" && isTogglePulse ? (<Fragment>
                          <div className="content" id="goldpulseid">
                            <div className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'shipment')}>
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Shipment</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon">< ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'shipment')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'shipment')} /></span>
                                )
                              }
                            </div>
                            <div className="horizontalline">
                              <hr></hr>
                            </div>


                            {elementId === 'shipment' && isToggle === true ? (
                              <Fragment>
                                <ul>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Silver Pulse +</Trans></p>
                                  <li>
                                    <Trans>Accounting Module Integration</Trans>
                                  </li>
                                  <li>
                                    <Trans>Barcode Label Printing</Trans>
                                  </li>
                                  <li>
                                    <Trans>Charge collection at destination</Trans>

                                  </li>
                                  <li>
                                    <Trans>Charge Division</Trans>
                                  </li>
                                  <li><Trans>Load Predefined Charges</Trans></li>
                                  <li><Trans>Multi Branch</Trans></li>
                                  <li><Trans>Profit Settlement between Branches</Trans></li>
                                  <li><Trans>State Log</Trans></li>
                                  <li><Trans>Summary Report</Trans></li>
                                  <li><Trans>Warehouse Integration</Trans></li>
                                </ul>

                                <div>
                                  <hr></hr>
                                </div>
                              </Fragment>
                            ) : null}

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'job')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Job</Trans>
                              {
                                isjobShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'job')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'job')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'job' && isToggle === true ? (

                              <ul>
                                <div>
                                  {" "}
                                  <hr></hr>
                                </div>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Silver Pulse +</Trans></p>
                                <li>
                                  <Trans>Accounting Module Integration</Trans>
                                </li>
                                <li>
                                  <Trans>Charge Collection at Destination</Trans>
                                </li>
                                <li>
                                  <Trans>Load Predefined Charges to Specific Route</Trans>
                                </li>
                                <li>
                                  <Trans>Load Shipment Charges to Job</Trans>

                                </li>
                                <li>
                                  <Trans>Master Job and Shipment Profit Loss Report</Trans>

                                </li>
                                <li>
                                  <Trans>Multi Leg Transportation</Trans>
                                </li>

                                <li><Trans>Profit and Loss Report</Trans></li>
                                <li><Trans>State Log</Trans></li>
                                <li><Trans>Summary Reports</Trans></li>
                                <li><Trans>Transportion Notes</Trans></li>



                                <div className="bals"></div>
                              </ul>

                            ) : null}

                            <hr className="height-balance"></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'delivery')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Delivery</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'delivery')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'delivery')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'delivery' && isToggle === true ? (
                              <ul>
                                <div>
                                  {" "}
                                  <hr></hr>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Silver Pulse +</Trans></p>
                                  <li>
                                    <Trans>Automate Delivery</Trans>
                                  </li>



                                </div>
                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'warehouse')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Warehouse</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'warehouse')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'warehouse')} /></span>
                                )
                              }
                            </div>

                            {elementId === 'warehouse' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Silver Pulse +</Trans></p>
                                <li>
                                  <Trans>Barcode Generation & Printing</Trans>
                                </li>
                                <li>
                                  <Trans>Confirmation of Issues</Trans>
                                </li>
                                <li>
                                  <Trans>Confirmation of Receipt</Trans>
                                </li>
                                <li>
                                  <Trans>Delivery to End Users</Trans>
                                </li>
                                <li>
                                  <Trans>
                                    Document Printing
                                  </Trans>
                                </li>
                                <li>
                                  <Trans>Excel Export</Trans>
                                </li>
                                <li>
                                  <Trans>Monthly Reports</Trans>
                                </li>
                                <li>
                                  <Trans>Receipt to freight forwarding link</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'employee')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Employee</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'employee')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'employee')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'employee' && isToggle === true ? (
                              <ul>
                                <div>

                                  <hr></hr>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Silver Pulse +</Trans></p>
                                </div>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'pickup')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Pick Up</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'pickup')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'pickup')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'pickup' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li>
                                  <Trans>Confirmation of Pickup</Trans>
                                </li>
                                <li>
                                  <Trans>Document Printing</Trans>
                                </li>
                                <li>
                                  <Trans>Excel Export</Trans>
                                </li>
                                <li><Trans>One Click Shipment/Warehouse Receipt</Trans></li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'accounting')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Accounting</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'accounting')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'accounting')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'accounting' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li>
                                  <Trans>
                                    Aging Report
                                  </Trans>
                                </li>
                                <li>
                                  <Trans>Balance Sheet</Trans>
                                </li>
                                <li>
                                  <Trans>Bank Cheque Features</Trans>
                                </li>
                                <li>
                                  <Trans>Bank Reconciliation</Trans>
                                </li>
                                <li>
                                  <Trans>Bill</Trans>
                                </li>
                                <li>
                                  <Trans>Chart of Accounts</Trans>

                                </li>
                                <li>

                                  <Trans>Credit Memo</Trans>
                                </li>
                                <li>
                                  <Trans>Debit Memo</Trans>
                                </li>
                                <li><Trans>Duty And Tax Summary</Trans></li>
                                <li><Trans>Expense Voucher</Trans></li>
                                <li><Trans>Invoice</Trans></li>
                                <li><Trans>Ledger Summary</Trans></li>
                                <li><Trans>Outstanding Report</Trans></li>
                                <li><Trans>Payment Summary</Trans></li>
                                <li><Trans>Profit And Loss</Trans></li>
                                <li><Trans>Receipt</Trans></li>
                                <li><Trans>Statement of Account (SOA)</Trans></li>
                                <li><Trans>Sundry Creditors</Trans></li>
                                <li><Trans>Sundry Debtors</Trans></li>
                                <li><Trans>Trading Profit And Loss</Trans></li>
                                <li><Trans>Trial Balance</Trans></li>
                                <li><Trans>Virtual Ledger</Trans></li>
                                <li><Trans>Voucher</Trans></li>
                                <li><Trans>Financial Period</Trans></li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'role')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Role and Permission</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'role')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'role')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'role' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Silver Pulse +</Trans></p>
                                </div>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'custom')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />

                              <Trans>Customer</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'custom')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'custom')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'custom' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li>
                                  <Trans>
                                    Payment Summary
                                  </Trans>
                                </li>
                                <li><Trans>Virtual Ledger</Trans> </li>


                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'report')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Quotation</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'report')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'report')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'report' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Silver Pulse +</Trans></p>
                                <li>
                                  <Trans>Mobile Notifications</Trans>
                                </li>
                                <li><Trans>Multi carrier based quoting</Trans></li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'inventory')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Multi Branch</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'inventory')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'inventory')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'inventory' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Silver Pulse +</Trans></p>
                                <li>
                                  <Trans>Communication between branch employees</Trans>
                                </li>
                                <li><Trans>Data sharing Between Branches and Agency,</Trans></li>
                                <li><Trans>EDI (Electronic data interchange)</Trans></li>
                                <li><Trans>Individual Balance Sheet</Trans></li>
                                <li><Trans>Multi Currency between Branches</Trans></li>
                                <li><Trans>Profit sharing Ratio between Branches</Trans></li>
                                <li><Trans>Status updates between Branches</Trans></li>

                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'portal')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Portal</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'portal')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'portal')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'portal' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li>
                                  <Trans>Tracking Portal</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'crm')}
                            >

                              <CancelOutlinedIcon className="icotesting" />
                              <Trans>CRM</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'crm')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'crm')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'crm' && isToggle === true ? (
                              <ul>
                                <hr></hr>

                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'dashboard')}
                            >
                              <CancelOutlinedIcon className="icotesting" />
                              {/* <CheckCircleOutlineOutlinedIcon className="materialstesting" /> */}
                              <Trans>Dashboard</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'dashboard')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'dashboard')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'dashboard' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                </div>

                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>


                            <div

                              className="fontcontent  content_arrange" id="divId" onClick={(e) => operation0(e, 'vendor')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Vendor</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'vendor')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'vendor')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'vendor' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                </div>
                                <li>
                                  <Trans>Payment Summary</Trans>

                                </li>
                                <li><Trans>Virtual Ledger</Trans></li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}



                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'user')}
                            >

                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>User</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'user')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'user')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'user' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Silver Pulse +</Trans></p>
                                </div>
                                {/* <li>
                                <Trans>User management</Trans>
                              </li> */}
                                <div className="bals"></div>
                              </ul>
                            ) : null}


                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'special')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Special Features</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'special')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'special')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'special' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                </div>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Silver Pulse +</Trans></p>
                                <li>
                                  <Trans>Audit Trail[Transaction Log]</Trans>
                                </li>
                                <li><Trans>Notification [SMS,Email, Inhouse notification features]</Trans></li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                          </div>
                          <div className="foot">
                            <div className="price">
                              <h5>
                                <Trans>To be more agile and to elaborate,</Trans>
                                <br></br>
                                <Trans>
                                  get the Gold
                                </Trans>
                              </h5>
                              {/* <a href="">Terms & Condition applied</a> */}
                            </div>
                            <div className="purchase-btn" lang={context.language}>
                              <Link to="/purchase/" lang={context.language}>
                                <Trans>BUY NOW</Trans>
                              </Link>
                            </div>
                          </div>
                        </Fragment>) : null}
                      </div>

                    </div>






                    <div className="col-md-4">
                      <div className="box box3">
                        <div className="head" onClick={() => minimizeone("id2")} id="id2">
                          <h3>
                            <Trans>Diamond Pulse</Trans>
                          </h3>
                          <Photo
                            src="white-logo.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        {id === "id2" && isTogglePulse ? (<Fragment>
                          <div className="content" id="diamondpulseid">
                            <div className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'shipment')}>
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Shipment</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon">< ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'shipment')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'shipment')} /></span>
                                )
                              }
                            </div>
                            <hr></hr>
                            {elementId === 'shipment' && isToggle === true ? (

                              <Fragment>
                                <ul>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                  <li>
                                    <Trans>Head Office and Branches</Trans>
                                  </li>
                                  <li>
                                    <Trans>Integration with Accounting Module</Trans>
                                  </li>
                                  <li>
                                    <Trans>Portal Integration [Tracking]</Trans>

                                  </li>
                                  <li><Trans>Status Change Notification, SMS, Email</Trans></li>

                                </ul>
                                <hr></hr>
                              </Fragment>
                            ) : null}
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'job')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Job</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'job')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'job')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'job' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                <li>
                                  <Trans>Branch Profit or Loss</Trans>
                                </li>
                                <li>
                                  <Trans>
                                    Cash Flow Statement
                                  </Trans>
                                </li>
                                <li><Trans>Landing</Trans></li>
                                <li><Trans>Settlement Summary</Trans></li>
                                <li><Trans>Settlements</Trans></li>

                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'delivery')}
                            >

                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Delivery</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'delivery')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'delivery')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'delivery' && isToggle === true ? (
                              <ul>
                                <div>
                                  {" "}
                                  <hr></hr>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                </div>

                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'warehouse')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Warehouse</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'warehouse')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'warehouse')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'warehouse' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                <li>
                                  <Trans>2D View of Warehouse Dashboard</Trans>
                                </li>
                                <li>
                                  <Trans>
                                    Auto Invoice
                                  </Trans>
                                </li>
                                <li>
                                  <Trans>Customizable Rack Structure Definition</Trans>
                                </li>
                                <li>
                                  <Trans>
                                    Online Warehouse Issue request
                                  </Trans>
                                </li>
                                <li>
                                  <Trans>Online Warehouse Receipt Request</Trans>
                                </li>
                                <li>
                                  <Trans></Trans>
                                </li>
                                <li>
                                  <Trans>ORack and Bin</Trans>
                                </li>
                                <li>
                                  <Trans>Upload document (Upload Excel file to create receipt)</Trans>
                                </li>
                                <li>
                                  <Trans>Warehouse to Warehouse Transportation</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'employee')}
                            >

                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Employee</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'employee')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'employee')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'employee' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'pickup')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Pick Up</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'pickup')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'pickup')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'pickup' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <li>
                                  <Trans>Mobile Notification</Trans>
                                </li>

                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'accounting')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Accounting</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'accounting')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'accounting')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'accounting' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                <li>
                                  <Trans>
                                    Auto Credit
                                  </Trans>
                                </li>
                                <li>
                                  <Trans>Batch Invoice</Trans>
                                </li>
                                <li>
                                  <Trans>Branches and Consolidated Balance Sheet</Trans>
                                </li>
                                <li>
                                  <Trans>Cash Flow Statement</Trans>
                                </li>
                                <li>
                                  <Trans>
                                    Journal Entries
                                  </Trans>
                                </li>
                                <li>
                                  <Trans>
                                    Multi Currency
                                  </Trans>
                                </li>
                                <li>
                                  <Trans>Recursive Journal Entries</Trans>
                                </li>
                                <li><Trans>Settlement & Payments</Trans></li>
                                <li><Trans>Tax rate settings</Trans></li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'role')}
                            >

                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Role and Permission</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'role')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'role')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'role' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'custom')}
                            >

                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Customer</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'custom')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'custom')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'custom' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>

                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'report')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Quotation</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'report')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'report')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'report' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                <li>
                                  <Trans>Payments</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'inventory')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />

                              <Trans>Multi Branch</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'inventory')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'inventory')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'inventory' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                  <li><Trans>Consolidated Balance sheet</Trans></li>
                                  <li><Trans>Invoice Creation from other Branch(Agent Invoice)</Trans></li>
                                  <li><Trans>Profit sharing and settlement between Branches</Trans></li>
                                  <li><Trans>SMS, Email, Internal Notification</Trans></li>
                                </div>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent  content_arrange" id="divId" onClick={(e) => operation0(e, 'portal')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />

                              <Trans>Portal</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'portal')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'portal')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'portal' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                  <li><Trans>Integration with customer website</Trans></li>
                                </div>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'crm')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>CRM</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'crm')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'crm')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'crm' && isToggle === true ? (
                              <ul>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                <li>
                                  <Trans>Document Printing</Trans>
                                </li>
                                <li>
                                  <Trans>Follow up</Trans>
                                </li>
                                <li>
                                  <Trans>Generate Quotation for Sales Lead</Trans>
                                </li>
                                <li>
                                  <Trans>Sales Lead Creation</Trans>
                                </li>
                                <li>
                                  <Trans>Status Based Report</Trans>
                                </li>
                                <li>
                                  <Trans>Summary Report</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>
                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'dashboard')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Dashboard</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'dashboard')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'dashboard')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'dashboard' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                </div>
                                <li>
                                  <Trans>KPI Dashboard</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}
                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'vendor')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Vendor</Trans>

                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'vendor')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'vendor')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'vendor' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                  <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                </div>
                                <li>
                                  <Trans>Report versions</Trans>
                                </li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}


                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'user')}
                            >

                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>User</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'user')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'user')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'user' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>

                                </div>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>

                                <div className="bals"></div>
                              </ul>
                            ) : null}

                            <hr></hr>

                            <div

                              className="fontcontent content_arrange" id="divId" onClick={(e) => operation0(e, 'special')}
                            >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" />
                              <Trans>Special Features</Trans>
                              {
                                isShowArrow ? (

                                  <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'special')} /></span>
                                ) : (
                                  <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'special')} /></span>
                                )
                              }
                            </div>
                            {elementId === 'special' && isToggle === true ? (
                              <ul>
                                <div>
                                  <hr></hr>
                                </div>
                                <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                                <li style={{ color: "red" }}><Trans>Desktop Application</Trans></li>
                                <li>
                                  <Trans>Authentication against other directories</Trans>
                                </li>
                                <li><Trans>Audit Timeline</Trans></li>
                                <li><Trans>Event Subscription in mobile</Trans></li>
                                <li><Trans>Report Engine (User Customizable Report)</Trans></li>
                                <div className="bals"></div>
                              </ul>
                            ) : null}




                          </div>
                          <div className="foot">
                            <div className="price">
                              <h5 className="sample_content">
                                <Trans>To stay on top,</Trans>
                                <br />
                                <Trans>
                                  drive ahead with Diamond.
                                </Trans>
                              </h5>
                              <div className="purchase-btn" lang={context.language}>
                                <Link to="/purchase/" lang={context.language}>
                                  <Trans>BUY NOW</Trans>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Fragment>) : null}
                      </div>

                    </div>
                  </div>
                </div>
              ) : null}

              {value === "2" ? (
                <div id="tab-11" className="tab-contents">
                  <Trans>This is tab 3 content</Trans>
                </div>
              ) : null}

              {value === "3" ? (
                <div id="tab-12" className="tab-contents">
                  <Trans>This is tab 4 content</Trans>
                </div>
              ) : null}

              {value === "4" ? (
                <div id="tab-13" className="tab-contents">
                  <Trans>This is tab 5 content</Trans>
                </div>
              ) : null}

              {value === "5" ? (
                <div id="tab-14" className="tab-contents">
                  <Trans>This is tab 6 content</Trans>
                </div>
              ) : null}

              {value === "6" ? (
                <div id="tab-7" className="tab-contentpda  pda-device">
                  <div className="row">
                    <div className="col-md-5">
                      <Photo
                        src="pda.png"
                        className="img-fluid mb-5 mb-md-0"
                        alt=""
                      />
                    </div>

                    <div className="col-md-7">
                      <div className="inner-div" lang={context.language}>
                        <Photo src="blue-logo.svg" className="img-fluid" alt="" />

                        <div className="logipulse">
                          <Trans>LogiPulse</Trans>
                          <br></br>
                          <Trans>PDA Device and Application</Trans>
                        </div>
                        <div className="purchase-button testings">
                          <a href="">
                            <Trans>PURCHASE NOW</Trans>
                          </a>
                        </div>

                        <div className="row">
                          <div className="col-md-6 logipulse_cont">
                            <div className="logipulse_small">
                              <Trans>Highlights</Trans>
                            </div>
                            <ul className="logipulse_contents">
                              <li>
                                <Trans>Sleek and Tough hand-held design</Trans>
                              </li>
                              <li>
                                <Trans>Supports Multi-OS</Trans>
                              </li>
                              <li>
                                <Trans>
                                  Repair service warranty up to 5 years
                                </Trans>
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-6  logipulse_cont">
                            <div className="logipulse_small">
                              <Trans>Specifications</Trans>
                            </div>
                            <ul className="logipulse_contents">
                              <li>
                                <Trans>
                                  Qualcomm® Snapdragon™ 801 processor with 2.26
                                  GHz quad-core CPU
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Imager for scanning of linear and 2D barcodes
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Display: 109.2 mm (4.3 in) W VGA (480 x 800),
                                  super-bright, sunlight viewable
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Processor: 2.26 GHz Qualcomm Snapdragon 801
                                  quad-core
                                </Trans>
                              </li>
                              <li>
                                <Trans>Memory: 2 GB RAM, 16 GB Flash</Trans>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {value === "8" ? (
                <div id="tab-15" className="tab-contents">
                  <Trans>This is tab 8 content</Trans>
                </div>
              ) : null}



              {value === "7" ? (
                <div id="tab-7" className="tab-contentpda  pda-device" lang={context.language}>
                  <div className="row">
                    <div className="col-md-5">
                      <Photo
                        src="pda.png"
                        className="img-fluid mb-5 mb-md-0"
                        alt=""
                      />
                    </div>

                    <div className="col-md-7">
                      <div className="inner-div" lang={context.language}>
                        <Photo src="blue-logo.svg" className="img-fluid" alt="" />

                        <div className="logipulse" lang={context.language}>
                          <Trans>LogiPulse</Trans>
                          <br></br>
                          <Trans>PDA Device and Application</Trans>
                        </div>
                        <div className="purchase-button testings" lang={context.language}>
                          <a href="">
                            <Trans>PURCHASE NOW</Trans>
                          </a>
                        </div>

                        <div className="row">
                          <div className="col-md-6 logipulse_cont" lang={context.language}>
                            <div className="logipulse_small" lang={context.language}>
                              <Trans>Highlights</Trans>
                            </div>
                            <ul className="logipulse_contents" lang={context.language}>
                              <li>
                                <Trans>Sleek and Tough hand-held design</Trans>
                              </li>
                              <li>
                                <Trans>Supports Multi-OS</Trans>
                              </li>
                              <li>
                                <Trans>
                                  Repair service warranty up to 5 years
                                </Trans>
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-6  logipulse_cont" lang={context.language}>
                            <div className="logipulse_small" lang={context.language}>
                              <Trans>Specifications</Trans>
                            </div>
                            <ul className="logipulse_contents" lang={context.language}>
                              <li>
                                <Trans>
                                  Qualcomm® Snapdragon™ 801 processor with 2.26
                                  GHz quad-core CPU
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Imager for scanning of linear and 2D barcodes
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Display: 109.2 mm (4.3 in) W VGA (480 x 800),
                                  super-bright, sunlight viewable
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Processor: 2.26 GHz Qualcomm Snapdragon 801
                                  quad-core
                                </Trans>
                              </li>
                              <li>
                                <Trans>Memory: 2 GB RAM, 16 GB Flash</Trans>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="container">
              <div className="row drops_content" lang={context.language}>
                <div className="col-sm-12">
                  <div className="purchaseContents" lang={context.language}>
                    {" "}
                    <Trans>Couldn't find the right choice?</Trans>
                    <br></br>
                    <Trans>Here is the Dedicated Package for you.</Trans>
                  </div>

                  <div className="subpurchase" lang={context.language}>
                  <Trans>A package private for growing enterprises to grow more,offering dedicated server, service, upgrade, and sales.</Trans>
                    <br></br><br></br>
                    <Trans>The enterprise-level service extends Logipulse cloud platform in exclusive pricing.</Trans>
                    <Trans>To know more details and costs Communicate with us now.</Trans>
                    {/* <br></br><br></br> <Trans>Communicate with us now</Trans> */}
                  </div>
                </div>
                <div className="purchasebuton" lang={context.language}>
                  <Trans>PURCHASE DEDICATED PACKAGE</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="features_mb d-block d-md-none">
        <div className="header_contents_sections text-right"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Photo
                src="Bottom Image-11.svg"
                className="innerimage"
                alt=""
              />
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}
export default Price

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
