import React from "react"
import Header from "./header"
import Footer from "../components/common/footer"
import Pricing_mbl from "../components/common/pricing_mbl"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useTranslation, I18nextContext ,Link} from "gatsby-plugin-react-i18next"


const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
const Pricing = () => {
  const { language } = React.useContext(I18nextContext)
  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/pricing/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/pricing/`
      const canoncalUrl = buildCanonicalUrl();
      const { t } = useTranslation();
      const buildHrefUrl = () =>
      language === "en"
        ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
        : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
    const hrefUrl = buildHrefUrl();
  return (
    <div className="Layout" id="layout">
      <Header />
      <Pricing_mbl />
      <Footer />
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          
  <!-- Google Tag Manager -->
  
          <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TK7M58Z');</script>
          
              `,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
          
  <!-- Google Tag Manager -->
  
          <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
          height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>    
              `,
          }}
        />
        <meta charSet="utf-8" />
        <title>{t("Freight Forwarding Solutions Pricing - Logipulse")}</title>

        <Link rel="canonical" href={canoncalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
        <meta
          name="description"
          content={t("Whether you are a beginner or if you run an established logistics firm, SaaS is the best choice as you don't have to pay for the whole software. Logipulse gives you the best pricing for tailored SaaS-based freight forwarding solutions.")}
        />
        <meta
          name="Keywords"
          content={t("freight forwarding software pricing, logistics software pricing, shipping software pricing, cargo software pricing")}
          data-react-helmet="true"
        />
       
      </Helmet>
    </div>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Pricing
