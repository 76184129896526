import React, { Fragment, useState } from "react";
import Photo from "../../components/Photo";
import "../../styles/pricing.scss";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { useTranslation, Trans, I18nextContext ,Link} from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


const Price = () => {
 
  const { t } = useTranslation()
  const [showMe, setShowMe] = useState(false)
  const [value, setValue] = useState("1")
  const [Deliveryshow, setDeliveryshow] = useState(false)
  const [Employeeshow, setEmployeeshow] = useState(false)
  const [RoleandshowMe, setRoleandshowMe] = useState(false)
  const [showMeaccount, setshowMeaccount] = useState(false)
  const [report, setreport] = useState(false)
  const [customdate, setcustomdate] = useState(false)
  const [Warehouse, setWarehouse] = useState(false)
  const [PickUp, setPickUp] = useState(false)
  const [Inventory, setInventory] = useState(false)
  const [PortalBasic, setPortalBasic] = useState(false)
  const [CRMshow, setCRMshow] = useState(false)
  const [showDashboard, setshowDashboard] = useState(false)
  const [Portal, setPortal] = useState(false)
  const [Reportversions, setReportversions] = useState(false)
  const [shipment, setShipment] = useState(false)

  const [isShowArrow, setIsShowArrow] = useState(false)
  const [isjobShowArrow, setIsjobShowArrow] = useState(false)
  const [Userversion, setuserversion] = useState(false)
  const [Specialfeatures, setSpecial] = useState(false)
  const [isToggle, setToggleValue] = useState(false);
  const [elementId, setElementId] = useState("");

  const heightEqualizer = () => {
    setTimeout(() => {
      let GreaterHeight = null;
      let silvedElem = document.querySelector("#silverpulseid > ul");
      let goldElem = document.querySelector("#goldpulseid > ul");
      let diamElem = document.querySelector("#diamondpulseid > ul");
      if (silvedElem != null && goldElem != null && diamElem != null) {
        let silvedElemHeight = silvedElem.clientHeight;
        let goldElemHeight = goldElem.clientHeight;
        let diamElemHeight = diamElem.clientHeight;
        if (silvedElemHeight >= goldElemHeight) {
          if (silvedElemHeight >= diamElemHeight) {
            GreaterHeight = silvedElemHeight;
          } else {
            GreaterHeight = diamElemHeight;
          }
        } else {
          if (goldElemHeight >= diamElemHeight) {
            GreaterHeight = goldElemHeight;
          } else {
            GreaterHeight = diamElemHeight;
          }
        }
        silvedElem.style.minHeight = GreaterHeight + "px";
        goldElem.style.minHeight = GreaterHeight + "px";
        diamElem.style.minHeight = GreaterHeight + "px";
      }

    }, 100)
  }
  const hideEqualizer = () => {
    setShipment(!shipment);
    setShowMe(!showMe);
    setDeliveryshow(!Deliveryshow);
    setEmployeeshow(!Employeeshow);
    setRoleandshowMe(!RoleandshowMe);
    setshowMeaccount(!showMeaccount);
    setreport(!report);
    setcustomdate(!customdate);
    setWarehouse(!Warehouse);
    setPickUp(!PickUp);
    setInventory(!Inventory);
    setPortalBasic(!PortalBasic);
    setCRMshow(!CRMshow);
    setshowDashboard(!showDashboard);
    setPortal(!Portal);
    setReportversions(!Reportversions);
    setuserversion(!Userversion);
    setSpecial(!Specialfeatures);


  }

  const operation0 = (event, contentId) => {
    let id = event.target.id;

    if (elementId != contentId) {
      setElementId(contentId);
      setToggleValue(true);
    } else {
      setElementId(contentId);
      setToggleValue(!isToggle);
    }

    if (id === "showRightArrow") {
      setIsShowArrow(true);
    }
    else if (id === "showDownArrow") {
      setIsShowArrow(false);
    }
    else if (id === "divId") {
      setIsShowArrow(!isShowArrow);
    }

    heightEqualizer();
  }

  const getTranslated = text => {
    return t(text);
  }

  const handleChange = event => {
    setValue(event.target.value)
    
  }

  return (
    
    <div>
      <div className="banner-feature pricing-features-header mbl_pricing_hide">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="overlay-freight"></div>

              <div className="right-img"></div>
            </div>

            <div className="col-md-12">
              <div className="content-imgs">
                <div className="pricing_details_cont" lang={I18nextContext._currentValue.language}>
                  <Link to="/"><Trans>HOME</Trans></Link> /
                  <span className="features_dkt">
                    <Link to="/pricing/"><Trans>PRICING</Trans></Link>
                  </span>{" "}
                </div>
                <div className="sub_main" lang={I18nextContext._currentValue.language}>
                  <Trans>Pick and pay only for what you need!</Trans>
                </div>
                <div className="subsub_main arabic_subsusb" lang={I18nextContext._currentValue.language}>
                  <Trans>
                    A tailored combination of solutions to achieve your target and beyond. Find the most suitable module combo for your business from an extensive array of options starting from basics to extras.
                  </Trans>
                  <br></br>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pricing-content mbl_pricing_hide">
        <div className="container">
          <div className="row divlook_align" lang={I18nextContext._currentValue.language}>
            <div className="col-md-12"></div>

            <div className="col-md-6">
              <div className="contents ">
                <Trans>Pay only for the service you use!</Trans>{" "}
              </div>
              <div className="first_content">
                <Trans>
                  The advantages of SaaS and Cloud software solutions are cost-efficient and advanced. If you are looking to establish the least resource and support, within a budget, SaaS is the answer.
                </Trans>
                <br></br>
                <div className="second_content">
                  <Trans>
                    You won’t have to worry about the integration, upgrade, scalability, and expert support.
                  </Trans>

                </div>
              </div>

            </div>

            <div className="col-md-6">

              <div className="contents ">
                <Trans>Pay only for the time you use!</Trans>{" "}
              </div>
              <div className="first_content">
                <Trans>
                  If you are experimenting or just a beginner or if you have an established logistics firm, SaaS is the best option as you need not have to pay for the whole software.
                </Trans>
                <br></br>
                <div className="second_content">
                  <Trans>
                    You can pick the Logipulse modules you require for your services, also you can choose the duration of usage, and pay only for that!
                  </Trans>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pricing-details mbl_pricing_hide">
        <div className="container">
          <div className="row  column_alignment" lang={I18nextContext._currentValue.language}>
            <div className="col-md-12"></div>

            <div className="col-md-5">
              <div className="tab-navigations" lang={I18nextContext._currentValue.language}>
                <select id="select-box" value={value} onChange={handleChange} className="restthis" lang={I18nextContext._currentValue.language}>
                  <option style={{ padding: "20px" }} value="0" label={getTranslated("Select a product")}>
                    {getTranslated("Select a product")}
                  </option>
                  <option style={{ padding: "20px" }} value="1" label={getTranslated("Freight Forwarding")}>
                    {getTranslated("Freight Forwarding")}
                  </option>
                  {/* <option value="2" label={getTranslated("3PL & Warehousing")}>
                    {getTranslated("3PL & Warehousing")}
                  </option> */}
                  {/* <option value="3" label={getTranslated("N V O C C")}>
                    {getTranslated("N V O C C")}
                  </option> */}
                  {/* <option
                    value="4"
                    label={getTranslated("Movers & Relocation")}
                  >
                    {getTranslated("Movers & Relocation")}
                  </option> */}
                  {/* <option value="6" label={getTranslated("Mobile Application")}>
                    {getTranslated("Mobile Application")}
                  </option> */}
                  {/* <option value="8" label={getTranslated("Customs Clearance")}>
                    {getTranslated("Customs Clearance")}
                  </option> */}
                  {/* <option
                    value="7"
                    label={getTranslated("PDA Device & Application")}
                  >
                    {getTranslated("PDA Device & Application")}
                  </option> */}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="detailed-tab mbl_pricing_hide" lang={I18nextContext._currentValue.language}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 position-tops" lang={I18nextContext._currentValue.language}>
              {value === "0" || value === "1" ? (
                <div id="tab-1" className="tab-contents freight-frwdn" lang={I18nextContext._currentValue.language}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="box box1">
                        <div className="head" lang={I18nextContext._currentValue.language}>
                          <h3>
                            <Trans>Silver Pulse</Trans>
                          </h3>
                          <Photo
                            src="white-logo.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content" id="silverpulseid">



                          <div className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'shipment')} style={{ cursor: "pointer" }}>
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Shipment</Trans>

                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'shipment')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'shipment')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                            <hr></hr>

                          </div>


                          {elementId === 'shipment' && isToggle === true ? (

                            <Fragment>
                              <ul id="shipmentul" lang={I18nextContext._currentValue.language}>
                                <li className="position_relative category_points commonhover">

                                  <Trans>Ocean, Air & Ground Shipments</Trans>
                                  {/* <div className="position_absolute content_explanation">
                                    <div className="arrow-up"></div>

                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                  </div> */}
                                </li>
                                <li>
                                  <Trans>Export, Import, Direct, House</Trans>
                                </li>
                                <li>
                                  <Trans>Copy shipment</Trans>
                                </li>
                                <li>
                                  <Trans>Consolidation</Trans>
                                </li>
                                <li>
                                  <Trans>One click manifest</Trans>
                                </li>
                                <li>
                                  <Trans>Individual shipment item tracking</Trans>
                                </li>
                                <li><Trans>Shipment state management</Trans></li>
                                <li><Trans>Damage, Missing, Recovery marking</Trans></li>
                                <li><Trans>Invoice/ Bill</Trans></li>
                                <li><Trans>Receipt/ Voucher/ Expense Voucher</Trans></li>
                                <li><Trans>Document printing</Trans></li>
                                <li><Trans>Income & Expense reports</Trans></li>                              
                              </ul>
                              <hr></hr>
                            </Fragment>

                          ) : null}






                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'job')} 
                            style={{ cursor: "pointer" }}>
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Job</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'job')} lang={I18nextContext._currentValue.language} /></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'job')}  lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>

                          {elementId === 'job' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <li>
                                <Trans>Consolidation</Trans>
                              </li>
                              <li>
                                <Trans>Manifest</Trans>
                              </li>
                              <li>
                                <Trans>Document printing</Trans>
                              </li>
                              <li>
                                <Trans>Consignment note</Trans>
                              </li>
                              <li>
                                <Trans>Air Waybill</Trans>
                              </li>
                              <li>
                                <Trans>
                                Ocean Bill of Lading
                                </Trans>
                              </li>
                              <li>
                                <Trans>Truck Waybill</Trans>
                              </li>
                              <li><Trans>Delivery Order</Trans></li>
                              <li><Trans>Invoice/ Bill</Trans></li>
                              <li><Trans>Receipt/ Voucher/ Expense Voucher</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'delivery')} 
                            style={{ cursor: "pointer" }} >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Delivery</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'delivery')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'delivery')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>

                          {elementId === 'delivery' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <li><Trans>Proof of Delivery</Trans></li>
                              <li><Trans>Dispatch Delivery</Trans></li>
                              <li><Trans>Item damage marking</Trans></li>
                              <li><Trans>Assign Employee/ Delivery Boy</Trans></li>
                              <li><Trans>Reopen and Modification</Trans></li>
                              <li><Trans>Excel Export</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'warehouse')} 
                            style={{ cursor: "pointer" }}>
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Warehouse</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'warehouse')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'warehouse')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>

                          {elementId === 'warehouse' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <li>
                                <Trans>Goods received note</Trans>
                              </li>
                              <li>
                                <Trans>Confirmation of issues</Trans>
                              </li>
                              <li><Trans>Confirmation of receipt</Trans></li>
                              <li><Trans>Goods issue notes</Trans></li>
                              <li><Trans>Invoice/ Bill</Trans></li>
                              <li><Trans>Receipt/ Voucher/ Expense Voucher</Trans></li>

                              <div className="bals"></div>

                              <br></br>
                            </ul>
                          ) : null}
                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'employee')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Employee</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'employee')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'employee')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'employee' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <li>
                                <Trans>Employee hierarchy</Trans>
                              </li>
                              <li>
                                <Trans>Role based employees</Trans>
                              </li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'pickup')} style={{ cursor: "pointer" }}
                          >
                            <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/>
                            <Trans>Pick Up</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'pickup')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'pickup')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'pickup' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                {" "}
                                <hr></hr>
                              </div>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>

                          <div
                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'accounting')} style={{ cursor: "pointer" }}
                          >
                             <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/>
                            <Trans>CRM</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'accounting')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'accounting')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'accounting' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              {/* <li>
                                <Trans>
                                  Financial Period
                                </Trans>
                              </li> */}

                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'role')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Quotation</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'role')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'role')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>

                          {elementId === 'role' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <li>
                                <Trans>Air, Ocean, Ground</Trans>
                              </li>
                              <li>
                                <Trans>Approval flow</Trans>
                              </li>
                              <li><Trans>Generate shipment/ pickup</Trans></li>
                              <li><Trans>Send email</Trans></li>
                              <li><Trans>Conversion report</Trans></li>
                              <li><Trans>Print and export PDF</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'custom')} style={{ cursor: "pointer" }}
                          >
                            <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/>
                            {/* <CheckCircleOutlineOutlinedIcon className="materialstesting" /> */}
                            <Trans>Accounting</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'custom')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'custom')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'custom' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              {/* <li>
                                <Trans>Custom date selection</Trans>
                              </li> */}
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'report')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Roles and Permissions</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'report')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'report')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>

                          {elementId === 'report' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <li>
                                <Trans>Agency/ branch wise permission</Trans>
                              </li>
                              <li><Trans>Customizable permission module</Trans></li>
                              
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'inventory')} style={{ cursor: "pointer" }}
                          >
                            {/* <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} /> */}
                            {/* <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/> */}
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            {/* <CancelOutlinedIcon className="icotesting" /> */}
                            <Trans>Customer</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'inventory')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'inventory')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'inventory' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                                {/* <li><Trans>Communication between branch employees</Trans></li> */}
                              </div>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'portal')} style={{ cursor: "pointer" }}
                          >
                            {/* <CancelOutlinedIcon className="icotesting"lang={I18nextContext._currentValue.language} /> */}
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Multi Branch</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'portal')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'portal')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'portal' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                                <li><Trans>Communication between branch employees</Trans></li>
                              </div>
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'crm')} style={{ cursor: "pointer" }}
                          >

                            <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/>
                            <Trans>Portal</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'crm')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'crm')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'crm' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <div className="bals"></div>

                            </ul>
                          ) : null}

                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'dashboard')} style={{ cursor: "pointer" }}
                          >
                            <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/>

                            <Trans>Dashboard</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'dashboard')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'dashboard')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'dashboard' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                              </div>

                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>


                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'vendor')} style={{ cursor: "pointer" }}
                          >
                            {/* <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/> */}
                            {/* <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/> */}
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            {/* <CheckCircleOutlineOutlinedIcon className="materialstesting" /> */}
                            <Trans>Vendor</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'vendor')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'vendor')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'vendor' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                              </div>
                              {/* <li>
                                <Trans>Vendor</Trans>
                              </li> */}
                              <div className="bals"></div>
                            </ul>
                          ) : null}



                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'user')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>User</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'user')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'user')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'user' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                              </div>
                              <li>
                                <Trans>User management</Trans>
                              </li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}





                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'special')}  style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Special Features</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'special')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'special')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'special' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                              </div>
                              <li>
                                <Trans>Support multi units</Trans>
                              </li>
                           
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                        </div>

                        <div className="foot">
                          <div className="price" lang={I18nextContext._currentValue.language} >
                            <h5>
                              <Trans>To optimize foundation operations,</Trans>
                              <br></br>
                              <Trans>
                                start with Silver.
                              </Trans>
                            </h5>
                            {/* <a href="">Terms & Condition applied</a> */}
                          </div>
                          <div className="purchase-btn" lang={I18nextContext._currentValue.language} >
                            <Link to="/purchase/"  lang={I18nextContext._currentValue.language}>
                              <Trans>BUY NOW</Trans>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-4">
                      <div className="box box2">
                        <div className="head" lang={I18nextContext._currentValue.language}>
                          <h3>
                            <Trans>Gold Pulse</Trans>
                          </h3>
                          <Photo
                            src="white-logo.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>

                        <div className="content" id="goldpulseid">
                          <div className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'shipment')} style={{ cursor: "pointer" }}> 
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Shipment</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon">< ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'shipment')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'shipment')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          <div className="horizontalline">
                            <hr></hr>
                          </div>


                          {elementId === 'shipment' && isToggle === true ? (
                            <Fragment>
                              <ul lang={I18nextContext._currentValue.language}>
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                                <li>
                                  <Trans>Multi Branch</Trans>
                                </li>
                                <li>
                                  <Trans>Barcode label printing</Trans>
                                </li>
                                <li>
                                  <Trans>
                                  Load predefined charges
                                  </Trans>
                                </li>
                                <li>
                                  <Trans>Charge collection at destination</Trans>
                                </li>
                                <li><Trans>Charge division</Trans></li>
                                <li><Trans>Profit settlement between branches</Trans></li>
                                <li><Trans>Accounting module integration</Trans></li>
                                <li><Trans>Warehouse integration</Trans></li>
                                <li><Trans>State log</Trans></li>
                                <li><Trans>Summary report</Trans></li>
                                <li><Trans>Excel export</Trans></li>
                              </ul>

                              <div>
                                <hr></hr>
                              </div>
                            </Fragment>
                          ) : null}

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'job')} 
                            style={{ cursor: "pointer" }}>
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Job</Trans>
                            {
                              isjobShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'job')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'job')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'job' && isToggle === true ? (

                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                {" "}
                                <hr></hr>
                              </div>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                              <li>
                                <Trans>Multi leg transportation</Trans>
                              </li>
                              <li>
                                <Trans>Transportation notes</Trans>
                              </li>
                              <li>
                                <Trans>Charge collection at Destination</Trans>
                              </li>
                              <li>
                                <Trans>
                                Load predefined charges to specific route
                                </Trans>
                              </li>
                              <li>
                                <Trans>Load shipment charges to Job</Trans>

                              </li>
                              <li>
                                <Trans>
                                Master Job and Shipment Profit Loss Report
                                </Trans>
                              </li>
                              <li><Trans>Profit and Loss report</Trans></li>
                              <li><Trans>Accounting module integration</Trans></li>
                              <li><Trans>State log</Trans></li>
                              <li><Trans>Summary reports</Trans></li>
                              <li><Trans>Excel export</Trans></li>



                              <div className="bals"></div>
                            </ul>

                          ) : null}

                          <hr className="height-balance"></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'delivery')} 
                            style={{ cursor: "pointer" }}>
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Delivery</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'delivery')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'delivery')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'delivery' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                {" "}
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                                <li>
                                  <Trans>Automate delivery</Trans>
                                </li>



                              </div>
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'warehouse')} 
                            style={{ cursor: "pointer" }}>
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Warehouse</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'warehouse')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'warehouse')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>

                          {elementId === 'warehouse' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                              <li>
                                <Trans>Barcode generation & printing</Trans>
                              </li>
                              <li>
                                <Trans>Delivery to end users</Trans>
                              </li>
                              <li>
                                <Trans>Receipt to freight forwarding link</Trans>
                              </li>
                              <li>
                                <Trans>Document printing</Trans>
                              </li>
                              <li>
                                <Trans>
                                Monthly reports
                                </Trans>
                              </li>
                              <li>
                                <Trans>Excel export</Trans>
                              </li>
                             
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'employee')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Employee</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'employee')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'employee')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'employee' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>

                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                              </div>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'pickup')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Pick Up</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'pickup')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'pickup')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'pickup' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <li>
                                <Trans>Pick up received notes</Trans>
                              </li>
                              <li>
                                <Trans>Confirmation of pickup</Trans>
                              </li>
                              <li>
                                <Trans>Document printing</Trans>
                              </li>
                              <li><Trans>Excel export</Trans></li>
                              <li><Trans>One click shipment/ warehouse receipt</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'accounting')} style={{ cursor: "pointer" }}
                          >
                             <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/>
                            {/* <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} /> */}
                            <Trans>CRM</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'accounting')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'accounting')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'accounting' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              {/* <li>
                                <Trans>
                                  Aging Report
                                </Trans>
                              </li>
                              <li>
                                <Trans>Balance Sheet</Trans>
                              </li>
                              <li>
                                <Trans>Bank Cheque Features</Trans>
                              </li>
                              <li>
                                <Trans>Bank Reconciliation</Trans>
                              </li>
                              <li>
                                <Trans>
                                  Bill
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Chart of Accounts
                                </Trans>
                              </li>
                              <li>

                                <Trans>Credit Memo</Trans>
                              </li>
                              <li>
                                <Trans>Debit Memo</Trans>
                              </li>
                              <li><Trans>Duty And Tax Summary</Trans></li>
                              <li><Trans>Expense Voucher</Trans></li>
                              <li><Trans>Invoice</Trans></li>
                              <li><Trans>Ledger Summary</Trans></li>
                              <li><Trans>Outstanding Report</Trans></li>
                              <li><Trans>Payment Summary</Trans></li>
                              <li><Trans>Profit And Loss</Trans></li>
                              <li><Trans>Receipt</Trans></li>
                              <li><Trans>Statement of Account (SOA)</Trans></li>
                              <li><Trans>Sundry Creditors</Trans></li>
                              <li><Trans>Sundry Debtors</Trans></li>
                              <li><Trans>Trading Profit And Loss</Trans></li>
                              <li><Trans>Trial Balance</Trans></li>
                              <li><Trans>Virtual Ledger</Trans></li>
                              <li><Trans>Voucher</Trans></li>
                              <li><Trans>Financial Period</Trans></li> */}
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'role')} style={{ cursor: "pointer" }} 
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Quotation</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'role')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'role')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'role' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                               
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                                <li><Trans>Multi carrier based quoting</Trans></li>
                              </div>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'custom')} style={{ cursor: "pointer" }}
                          >
                           <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            {/* <CancelOutlinedIcon className="icotesting" /> */}
                            <Trans>Accounting</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'custom')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'custom')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'custom' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <li>
                                <Trans>
                                Aging report
                                </Trans>
                              </li>
                              <li><Trans>Balance sheet</Trans> </li>
                              <li><Trans>Bank cheque features</Trans></li>
                              <li><Trans>Bank reconciliation</Trans></li>
                              <li><Trans>Bill</Trans></li>
                              <li><Trans>Chart of Accounts</Trans></li>
                              <li><Trans>Credit Memo</Trans></li>
                              <li><Trans>Debit Memo</Trans></li>
                              <li><Trans>Duty And tax Summary</Trans></li>
                              <li><Trans>Expense voucher</Trans></li>
                              <li><Trans>Invoice</Trans></li>
                              <li><Trans>Ledger summary</Trans></li>
                              <li><Trans>Outstanding report</Trans></li>
                              <li><Trans>Payment summary</Trans></li>
                              <li><Trans>Profit And Loss</Trans></li>
                              <li><Trans>Receipt</Trans></li>
                              <li><Trans>Statement of Account (SOA)</Trans></li>
                              <li><Trans>Sundry creditors</Trans></li>
                              <li><Trans>Sundry debtors</Trans></li>
                              <li><Trans>Trading profit and loss</Trans></li>
                              <li><Trans>Trial balance</Trans></li>
                              <li><Trans>Virtual ledger</Trans></li>
                              <li><Trans>Voucher</Trans></li>
                              <li><Trans>Financial period</Trans></li>

                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'report')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Roles and Permissions</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'report')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'report')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'report' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                              {/* <li>
                                <Trans>Mobile Notifications</Trans>
                              </li>
                              <li><Trans>Multi carrier based quoting</Trans></li> */}
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'inventory')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Customer</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'inventory')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'inventory')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'inventory' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                              <li>
                                <Trans>Payment Summary</Trans>
                              </li>
                              <li><Trans>Virtual Ledger</Trans></li>
                              {/* <li><Trans>EDI (Electronic data interchange)</Trans></li>
                              <li><Trans>Individual Balance Sheet</Trans></li>
                              <li><Trans>Multi Currency between Branches</Trans></li>
                              <li><Trans>Profit sharing Ratio between Branches</Trans></li>
                              <li><Trans>Status updates between Branches</Trans></li> */}

                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'portal')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Multi Branch</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'portal')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'portal')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'portal' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                              <li>
                                <Trans>Data sharing Between Branches and Agency</Trans>
                              </li>
                              <li><Trans>EDI (Electronic data interchange)</Trans></li>
                              <li><Trans>Individual balance sheet</Trans></li>
                              <li><Trans>Multi currency between branches</Trans></li>
                              <li><Trans>Profit sharing ratio between branches</Trans></li>
                              <li><Trans>Status updates between branches</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'crm')} style={{ cursor: "pointer" }}
                          >
                              <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            {/* <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/> */}
                            <Trans>Portal</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'crm')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'crm')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'crm' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                                <li><Trans>Tracking portal</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'dashboard')} style={{ cursor: "pointer" }}
                          >
                            
                            <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/>
                            {/* <CheckCircleOutlineOutlinedIcon className="materialstesting" /> */}
                            <Trans>Dashboard</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'dashboard')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'dashboard')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'dashboard' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                              </div>

                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>


                          <div className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'vendor')} style={{ cursor: "pointer" }} 
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Vendor</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'vendor')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'vendor')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'vendor' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                              </div>
                              <li>
                                <Trans>Payment Summary</Trans>

                              </li>
                              <li><Trans>Virtual Ledger</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}



                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'user')} style={{ cursor: "pointer" }}
                          >
                            {/* <CancelOutlinedIcon className="icotesting" /> */}
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>User</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'user')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'user')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'user' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                              </div>
                              {/* <li>
                                <Trans>User management</Trans>
                              </li> */}
                              <div className="bals"></div>
                            </ul>
                          ) : null}


                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'special')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Special Features</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'special')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'special')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'special' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                              </div>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Silver Pulse +</Trans></p>
                              <li>
                                <Trans>Audit Trail [Transaction Log]</Trans>
                              </li>
                              <li><Trans>Notification [SMS,email, Inhouse notification features]</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                        </div>
                        <div className="foot">
                          <div className="price" lang={I18nextContext._currentValue.language} >
                            <h5>
                              <Trans>To be more agile and to elaborate,</Trans>
                              <br></br>
                              <Trans>
                                get the Gold
                              </Trans>
                            </h5>
                            {/* <a href="">Terms & Condition applied</a> */}
                          </div>
                          <div className="purchase-btn" lang={I18nextContext._currentValue.language} >
                            <Link to="/purchase/"  lang={I18nextContext._currentValue.language}>
                              <Trans>BUY NOW</Trans>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>






                    <div className="col-md-4">
                      <div className="box box3">
                        <div className="head" lang={I18nextContext._currentValue.language}>
                          <h3>
                            <Trans>Diamond Pulse</Trans>
                          </h3>
                          <Photo
                            src="white-logo.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content" id="diamondpulseid">
                          <div className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'shipment')} style={{ cursor: "pointer" }}> 
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Shipment</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon">< ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'shipment')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'shipment')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          <hr></hr>
                          {elementId === 'shipment' && isToggle === true ? (

                            <Fragment>
                              <ul lang={I18nextContext._currentValue.language}>
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                                <li>
                                  <Trans>Head Office and Branches</Trans>
                                </li>
                                <li>
                                  <Trans>Integration with Accounting module --Third Party software integration</Trans>
                                </li>
                                <li>
                                  <Trans>Portal Integration [Tracking]</Trans>

                                </li>
                                <li><Trans>Status Change Notification, SMS, Email</Trans></li>

                              </ul>
                              <hr></hr>
                            </Fragment>
                          ) : null}
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'job')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Job</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'job')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'job')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'job' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              <li>
                                <Trans>Branch Profit or Loss</Trans>
                              </li>
                              <li>
                                <Trans>
                                Settlements
                                </Trans>
                              </li>
                              <li><Trans>Cash flow statement</Trans></li>
                              <li><Trans>Landing</Trans></li>
                              <li><Trans>Settlement summary</Trans></li>

                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'delivery')} 
                            style={{ cursor: "pointer" }}>
                            {/* <CancelOutlinedIcon className="icotesting" /> */}
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Delivery</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'delivery')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'delivery')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'delivery' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                {" "}
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              </div>
                              <li>
                                <Trans>Notifications</Trans>
                              </li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'warehouse')} 
                            style={{ cursor: "pointer" }}>
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Warehouse</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'warehouse')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'warehouse')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'warehouse' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              <li>
                                <Trans>Auto invoice</Trans>
                              </li>
                              <li>
                                <Trans>
                                2D view of warehouse dashboard
                                </Trans>
                              </li>
                              <li>
                                <Trans>Customizable rack structure definition</Trans>
                              </li>
                              <li>
                                <Trans>
                                Rack and Bin
                                </Trans>
                              </li>
                              <li>
                                <Trans>Online warehouse receipt request</Trans>
                              </li>
                              <li>
                                <Trans>Online warehouse issue request</Trans>
                              </li>
                              <li>
                                <Trans>Upload document (upload excel file to create receipt)</Trans>
                              </li>
                              <li>
                                <Trans>Warehouse to warehouse transportation</Trans>
                              </li>
                              
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'employee')} style={{ cursor: "pointer" }}
                            >
                            {/* <CancelOutlinedIcon className="icotesting" /> */}
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Employee</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'employee')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'employee')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'employee' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'pickup')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Pick Up</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'pickup')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'pickup')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'pickup' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              <li>
                                <Trans>Mobile Notification</Trans>
                              </li>

                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'accounting')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>CRM</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'accounting')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'accounting')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'accounting' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              <li>
                                <Trans>
                                Follow up
                                </Trans>
                              </li>
                              <li>
                                <Trans>Sales lead creation</Trans>
                              </li>
                              <li>
                                <Trans>Generate quotation for sales lead</Trans>
                              </li>
                              <li>
                                <Trans>Status based report</Trans>
                              </li>
                              <li>
                                <Trans>
                                Document printing
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                Summary report
                                </Trans>
                              </li>
                              
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'role')} style={{ cursor: "pointer" }}
                          >
                            {/* <CancelOutlinedIcon className="icotesting" /> */}
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Quotation</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'role')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'role')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'role' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                             
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              <li><Trans>Mobile notifications</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'custom')} style={{ cursor: "pointer" }}
                          >
                            {/* <CancelOutlinedIcon className="icotesting" /> */}
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Accounting</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'custom')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'custom')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'custom' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              <li>
                                <Trans>Auto credit</Trans>
                              </li>
                              <li><Trans>Batch invoice</Trans></li>
                              <li><Trans>Branches and consolidated balance sheet</Trans></li>
                              <li><Trans>Cash flow statement</Trans></li>
                              <li><Trans>Journal entries</Trans></li>
                              <li><Trans>Multi currency</Trans></li>
                              <li><Trans>Recursive journal entries</Trans></li>
                              <li><Trans>Settlement & payments</Trans></li>
                              <li><Trans>Tax rate settings</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'report')} style={{ cursor: "pointer" }}
                          >
                            {/* <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} /> */}
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Roles and Permissions</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'report')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'report')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'report' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              {/* <li>
                                <Trans>Payments</Trans>
                              </li> */}
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'inventory')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            {/* <CheckCircleOutlineOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/> */}
                            {/* <CancelOutlinedIcon className="icotesting" /> */}
                            <Trans>Customer</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'inventory')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'inventory')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'inventory' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                                {/* <li><Trans>Consolidated Balance sheet</Trans></li>
                                <li><Trans>Invoice Creation from other Branch(Agent Invoice)</Trans></li>
                                <li><Trans>Profit sharing and settlement between Branches</Trans></li>
                                <li><Trans>SMS, Email, Internal Notification</Trans></li> */}
                              </div>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'portal')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            {/* <CancelOutlinedIcon className="icotesting" /> */}
                            <Trans>Multi Branch</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'portal')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'portal')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'portal' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                                <li><Trans>Consolidated balance sheet</Trans></li>
                                <li><Trans>Invoice creation from other branch (agent invoice)</Trans></li>
                                <li><Trans>Profit sharing and settlement between branches</Trans></li>
                                <li><Trans>SMS, email, internal notification</Trans></li>
                                
                              </div>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div
 
                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'crm')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Portal</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'crm')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'crm')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'crm' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <hr></hr>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              <li>
                                <Trans>Integration with customer website</Trans>
                              </li>
                              {/* <li>
                                <Trans>Follow up</Trans>
                              </li>
                              <li>
                                <Trans>Generate Quotation for Sales Lead</Trans>
                              </li>
                              <li>
                                <Trans>Sales Lead Creation</Trans>
                              </li>
                              <li>
                                <Trans>Status Based Report</Trans>
                              </li>
                              <li>
                                <Trans>Summary Report</Trans>
                              </li> */}
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>
                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'dashboard')} style={{ cursor: "pointer" }}
                          >
                             <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            {/* <CancelOutlinedIcon className="icotesting" lang={I18nextContext._currentValue.language}/> */}
                            <Trans>Dashboard</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'dashboard')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'dashboard')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'dashboard' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                              </div>
                              <li>
                                <Trans>KPI Dashboard</Trans>
                              </li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}
                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'vendor')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Vendor</Trans>

                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'vendor')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'vendor')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'vendor' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                                <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              </div>
                              <li>
                                <Trans>Report versions</Trans>
                              </li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}


                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'user')} style={{ cursor: "pointer" }}
                          >
                            {/* <CancelOutlinedIcon className="icotesting" /> */}
                            
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            {/* <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} /> */}
                            <Trans>User</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'user')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'user')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'user' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>

                              </div>
                              <p style={{ color: "#37BF07", fontSize: "12px" }} className="pricelistcontent" lang={I18nextContext._currentValue.language}><Trans>Gold Pulse +</Trans></p>
                              {/* <li>
                                <Trans>User management</Trans>
                              </li> */}
                              <div className="bals"></div>
                            </ul>
                          ) : null}

                          <hr></hr>

                          <div

                            className="fontcontent content_arrange" lang={I18nextContext._currentValue.language} id="divId" onClick={(e) => operation0(e, 'special')} style={{ cursor: "pointer" }}
                          >
                            <CheckCircleOutlineOutlinedIcon className="materialstesting" lang={I18nextContext._currentValue.language} />
                            <Trans>Special Features</Trans>
                            {
                              isShowArrow ? (

                                <span className="chevronRightIcon"><ExpandMoreIcon id="showDownArrow" onClick={(e) => operation0(e, 'special')} lang={I18nextContext._currentValue.language}/></span>
                              ) : (
                                <span className="chevronRightIcon"><ChevronRightIcon id="showRightArrow" onClick={(e) => operation0(e, 'special')} lang={I18nextContext._currentValue.language}/></span>
                              )
                            }
                          </div>
                          {elementId === 'special' && isToggle === true ? (
                            <ul lang={I18nextContext._currentValue.language}>
                              <div>
                                <hr></hr>
                              </div>
                              <p style={{ color: "#37BF07", fontSize: "12px" }}><Trans>Gold Pulse +</Trans></p>
                              <li style={{ color: "red" }}><Trans>Desktop Application</Trans></li>
                              <li>
                                <Trans>Authentication against other directories</Trans>
                              </li>
                              <li><Trans>Audit Timeline</Trans></li>
                              <li><Trans>Event Subscription in mobile</Trans></li>
                              <li><Trans>Report Engine (User Customizable Report)</Trans></li>
                              <div className="bals"></div>
                            </ul>
                          ) : null}




                        </div>
                        <div className="foot">
                          <div className="price">
                            <h5 className="sample_content" lang={I18nextContext._currentValue.language} >
                              <Trans>To stay on top,</Trans>
                              <br />
                              <Trans>
                                drive ahead with Diamond.
                              </Trans>
                            </h5>
                            <div className="purchase-btn" lang={I18nextContext._currentValue.language}>
                              <Link to="/purchase/" lang={I18nextContext._currentValue.language}>
                                <Trans>BUY NOW</Trans>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {value === "2" ? (
                <div id="tab-11" className="tab-contents">
                  <Trans>This is tab 3 content</Trans>
                </div>
              ) : null}

              {value === "3" ? (
                <div id="tab-12" className="tab-contents">
                  <Trans>This is tab 4 content</Trans>
                </div>
              ) : null}

              {value === "4" ? (
                <div id="tab-13" className="tab-contents">
                  <Trans>This is tab 5 content</Trans>
                </div>
              ) : null}

              {value === "5" ? (
                <div id="tab-14" className="tab-contents">
                  <Trans>This is tab 6 content</Trans>
                </div>
              ) : null}

              {value === "6" ? (
                <div id="tab-7" className="tab-contentpda  pda-device" lang={I18nextContext._currentValue.language}>
                  <div className="row">
                    <div className="col-md-5">
                      <Photo
                        src="pda.png"
                        className="img-fluid mb-5 mb-md-0"
                        alt=""
                      />
                    </div>

                    <div className="col-md-7">
                      <div className="inner-div" lang={I18nextContext._currentValue.language}>
                        <Photo src="blue-logo.svg" className="img-fluid pricing_logo" alt="" lang={I18nextContext._currentValue.language}/>

                        <div className="logipulse" lang={I18nextContext._currentValue.language}>
                          <Trans>LogiPulse</Trans>
                          <br></br>
                          <Trans>PDA Device and Application</Trans>
                        </div>
                        <div className="purchase-button testings" lang={I18nextContext._currentValue.language}>
                          <a href="">
                            <Trans>PURCHASE NOW</Trans>
                          </a>
                        </div>

                        <div className="row">
                          <div className="col-md-6 logipulse_cont" lang={I18nextContext._currentValue.language}>
                            <div className="logipulse_small">
                              <Trans>Highlights</Trans>
                            </div>
                            <ul className="logipulse_contents" lang={I18nextContext._currentValue.language}>
                              <li lang={I18nextContext._currentValue.language}>
                                <Trans>Sleek and Tough hand-held design</Trans>
                              </li>
                              <li>
                                <Trans>Supports Multi-OS</Trans>
                              </li>
                              <li>
                                <Trans>
                                  Repair service warranty up to 5 years
                                </Trans>
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-6  logipulse_cont"  lang={I18nextContext._currentValue.language}>
                            <div className="logipulse_small">
                              <Trans>Specifications</Trans>
                            </div>
                            <ul className="logipulse_contents" lang={I18nextContext._currentValue.language}>
                              <li>
                                <Trans>
                                  Qualcomm® Snapdragon™ 801 processor with 2.26
                                  GHz quad-core CPU
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Image for scanning of linear and 2D barcodes
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Display: 109.2 mm (4.3 in) W VGA (480 x 800),
                                  super-bright, sunlight viewable
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Processor: 2.26 GHz Qualcomm Snapdragon 801
                                  quad-core
                                </Trans>
                              </li>
                              <li>
                                <Trans>Memory: 2 GB RAM, 16 GB Flash</Trans>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {value === "8" ? (
                <div id="tab-15" className="tab-contents">
                  <Trans>This is tab 8 content</Trans>
                </div>
              ) : null}



              {value === "7" ? (
                <div id="tab-7" className="tab-contentpda  pda-device" lang={I18nextContext._currentValue.language}>
                  <div className="row">
                    <div className="col-md-5">
                      <Photo
                        src="pda.png"
                        className="img-fluid mb-5 mb-md-0"
                        alt=""
                      />
                    </div>

                    <div className="col-md-7">
                      <div className="inner-div" lang={I18nextContext._currentValue.language}>
                        <Photo src="blue-logo.svg" className="img-fluid pricing_logo" alt="" lang={I18nextContext._currentValue.language}/>

                        <div className="logipulse" lang={I18nextContext._currentValue.language}>
                          <Trans>LogiPulse</Trans>
                          <br></br>
                          <Trans>PDA Device and Application</Trans>
                        </div>
                        <div className="purchase-button testings" lang={I18nextContext._currentValue.language}>
                          <a href="">
                            <Trans>PURCHASE NOW</Trans>
                          </a>
                        </div>

                        <div className="row">
                          <div className="col-md-6 logipulse_cont" lang={I18nextContext._currentValue.language}>
                            <div className="logipulse_small">
                              <Trans>Highlights</Trans>
                            </div>
                            <ul className="logipulse_contents" lang={I18nextContext._currentValue.language}>
                              <li lang={I18nextContext._currentValue.language}>
                                <Trans>Sleek and Tough hand-held design</Trans>
                              </li>
                              <li>
                                <Trans>Supports Multi-OS</Trans>
                              </li>
                              <li>
                                <Trans>
                                  Repair service warranty up to 5 years
                                </Trans>
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-6  logipulse_cont">
                            <div className="logipulse_small">
                              <Trans>Specifications</Trans>
                            </div>
                            <ul className="logipulse_contents" lang={I18nextContext._currentValue.language}>
                              <li>
                                <Trans>
                                  Qualcomm® Snapdragon™ 801 processor with 2.26
                                  GHz quad-core CPU
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Image for scanning of linear and 2D barcodes
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Display: 109.2 mm (4.3 in) W VGA (480 x 800),
                                  super-bright, sunlight viewable
                                </Trans>
                              </li>
                              <li>
                                <Trans>
                                  Processor: 2.26 GHz Qualcomm Snapdragon 801
                                  quad-core
                                </Trans>
                              </li>
                              <li>
                                <Trans>Memory: 2 GB RAM, 16 GB Flash</Trans>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="container mbl_pricing_hide">
              <div className="row drops_content">
                <div className="col-sm-12">
                  <div className="purchaseContents" lang={I18nextContext._currentValue.language} >
                    {" "}
                    <Trans>Couldn't find the right choice?</Trans>
                    <br></br>
                    <Trans>Here is the Dedicated Package for you.</Trans>
                  </div>

                  <div className="subpurchase" lang={I18nextContext._currentValue.language}>
                    <Trans>A package private for growing enterprises to grow more,offering dedicated server, service, upgrade, and sales.</Trans>
                    <br></br>
                    <Trans>The enterprise-level service extends Logipulse cloud platform in exclusive pricing.</Trans>       
                    <Trans>To know more details and costs</Trans>
                    <br></br> <Trans>Communicate with us now.</Trans>
                  </div>
                </div>
                <div className="purchasebuton" lang={I18nextContext._currentValue.language}>
                  <Trans>PURCHASE DEDICATED PACKAGE</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="end_divis_pricing mbl_pricing_hide">
        <div className="header_contents_sections text-right"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Photo
                src="Bottom Image-11.svg"
                className="innerimage"
                alt=""
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
export default Price;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
